#ac-footer-container {
  width: 100%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  .ac-footer-wrapper {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 40px 20px 20px 20px;
    color: #ffffff;

    .footer-icons {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 300px;
      }
      .social-network-icons {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        width: 300px;
        justify-content: space-between;
        a {
          padding: 10px;
          background-color: #2895d5;
          border-radius: 50%;
          img {
            width: 25px;
            height: 25px;
            background-color: #2895d5;
          }
        }
      }
    }

    .footer-site-text {
      width: 80%;
    }

    .copyright-legal {
      grid-column-start: 1;
      grid-column-end: 3;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .copyright {
        width: 40%;
        border-top: 1px solid #333740;
        p {
          margin: 10px 0 0 0;
          font-size: 14px;
        }
      }
      .legal-links {
        display: flex;
        justify-content: space-evenly;
        width: 40%;
        border-top: 1px solid #333740;
        font-size: 12px;
        a {
          color: #ffffff;
          text-decoration: none;
          margin: 10px 0 0 0;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }

    @media only screen and (max-width: 1080px) {
      grid-template-columns: 1fr;

      .footer-icons {
        align-items: unset;

        img {
          @media only screen and (max-width: 500px) {
            width: 260px;
          }
        }

        .social-network-icons {
          position: absolute;
          bottom: 150px;

          @media only screen and (max-width: 500px) {
            width: 260px;
          }
        }
      }

      .footer-site-text {
        margin: 30px 0 100px 0;

        @media only screen and (max-width: 500px) {
          width: 100%;
        }
      }

      .copyright-legal {
        grid-column-start: 1;
        grid-column-end: 1;
        flex-direction: column;

        .copyright {
          width: 100%;
          text-align: center;
          border: none;
        }
        .legal-links {
          width: 100%;
          border: none;
        }
      }
    }
  }
}
