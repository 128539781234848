.single-step-accordion-container {
  width: 100%;
  padding: 25px;
  background-color: #fff !important;
  &.center-step {
    margin-top: 400px;
  }

  h2 {
    margin-bottom: 16px;
  }

  @media only screen and (min-width: 768px) {
    h2 {
      margin-bottom: 8px;
    }
  }

  @media only screen and (min-width: 768px) {
    width: auto;
    margin: 0;
  }

  @media only screen and (min-width: 1080px) {
    width: calc(100% - 25px);
    margin-left: 25px;
  }

  .hide-it-on-mobile {
    display: none;

    @media only screen and (min-width: 1080px) {
      display: inline-block;
    }
  }

  .accordion-header {
    background-color: #fff !important;
    padding-left: 0;
    display: block;

    @media only screen and (min-width: 1080px) {
      box-shadow: unset;
    }

    &:focus {
      border: 0;
      box-shadow: unset;
    }

    .accordion-step-number {
      font-weight: 800;
      font-size: 14px;
      line-height: 17px;
      color: #00286e;
      position: absolute;
      top: 0;
      padding-bottom: 2px;

      @media only screen and (min-width: 1080px) {
        position: unset;
        font-size: 28px;
        line-height: 36px;
        color: #333740;
        padding-bottom: 0;
      }
    }

    .accordion-step-title {
      width: 79%;
      font-weight: 800;
      font-size: 22px;
      line-height: 26px;
      color: #00286e;
      margin-top: 10px;

      @media only screen and (min-width: 1080px) {
        margin-top: 0;
        font-size: 28px;
        line-height: 36px;
        margin-left: 12px;
        color: #333740;
      }
    }
  }

  .accordion-button {
    &::after {
      width: 20px;
      height: 10px;
      position: absolute;
      right: 0;
      top: 42%;
      background-image: url("../../assets/accordion-arrow.svg") !important;
    }
    &.collapsed {
      padding-bottom: 0;
      @media only screen and (min-width: 1080px) {
        padding-bottom: 16px;
      }
    }
  }

  // accordion body
  .disclaimer-ribbon-yellow {
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    color: #000000;
    text-align: center;
    background: rgba(254, 216, 0, 0.2);
    border-radius: 64px;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;

    @media only screen and (min-width: 1080px) {
      font-size: 16px;
    }
  }

  .disclaimer-ribbon-bluish {
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    color: #132787;
    text-align: center;
    background: rgba(0, 40, 110, 0.04);
    border-radius: 64px;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;

    @media only screen and (min-width: 1080px) {
      font-size: 16px;
    }
  }

  .accordion-txt-smaller {
    font-weight: 700;
    font-size: 14px;
    color: #333740;
    margin: 20px 0 4px 0;
  }

  .single-step-accordion-checkbox-wrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 12px;

    img {
      width: 8px;
      height: 8px;
      margin-right: 16px;
      position: relative;
      top: 6px;

      @media only screen and (min-width: 1080px) {
        top: 11px;
      }
    }

    label,
    p {
      font-size: 17px;
      line-height: 23px;
      color: #333740;
      font-weight: 500;

      @media only screen and (min-width: 1080px) {
        line-height: 30px;
        font-size: 20px;
      }

      b {
        font-weight: 700;
      }

      a {
        color: #132787;

        &:active,
        &:hover {
          color: #009cde;
        }
      }
    }

    label {
      a &:hover {
        cursor: pointer;
      }
    }
  }

  .step1-disclaimer-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 32px;
    background: #f5f6f9;
    border-radius: 12px;
    padding: 12px;

    @media only screen and (min-width: 1080px) {
      justify-content: space-between;
      padding: 21px 26px;
      margin-top: 20px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: #333740;
      font-weight: 600;
      width: 75%;
      margin: 0;

      @media only screen and (min-width: 1080px) {
        line-height: 24px;
        font-size: 17px;
        width: 86%;
      }

      a {
        color: #132787;

        &:active,
        &:hover {
          color: #009cde;
        }
      }
    }

    img {
      width: 36px;
      height: auto;

      @media only screen and (min-width: 1080px) {
        width: 56px;
        height: auto;
      }
    }
  }

  ul {
    margin-top: 16px;

    li {
      margin-bottom: 5px;
    }
  }

  .single-step-accordion-txt-only {
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: #333740;
    margin-top: 12px;

    @media only screen and (min-width: 1080px) {
      font-size: 20px;
      line-height: 30px;
    }

    a {
      color: #132787;

      &:active,
      &:hover {
        color: #009cde;
      }
    }
  }

  .step5-additional-help {
    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 10px;

      @media only screen and (min-width: 1080px) {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
      }
    }
  }

  .step5-disclaimer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    border: 1px solid #ece8e3;
    border-radius: 12px;

    @media only screen and (min-width: 1080px) {
      padding: 20px;
      flex-direction: row;
      justify-content: space-between;
    }

    .step5-disclaimer-txt-wrapper {
      color: #333740;

      @media only screen and (min-width: 1080px) {
        width: 49%;
      }

      h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 10px;

        @media only screen and (min-width: 1080px) {
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 20px;
        }
      }

      p {
        font-size: 17px;
        line-height: 27px;

        @media only screen and (min-width: 1080px) {
          font-size: 20px;
          line-height: 30px;
        }

        a {
          color: #132787;

          &:active,
          &:hover {
            color: #009cde;
          }
        }
      }
    }

    .step5-disclaimer-chat-icon-wrapper {
      width: 100%;
      background: #f5f6f9;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      padding-top: 21px;
      padding-bottom: 21px;

      @media only screen and (min-width: 1080px) {
        width: 49%;
        padding-top: 50px;
        padding-bottom: 50px;
      }

      img {
        width: 100px;
        height: auto;

        @media only screen and (min-width: 1080px) {
          width: 121px;
        }
      }
    }
  }
}
