#abortion-type-disclaimer-container {
  width: 100%;
  background-color: #fff;
  padding: 25px;
  border-top: 1px solid #f7f6f4;
  display: flex;
  align-items: center;
  &.sticky {
    position: sticky;
    top: 170px;
    z-index: 5;
  }
  &.fixed {
    position: fixed;
    top: 170px;
    z-index: 5;
    padding: 25px;
  }

  @media only screen and (min-width: 1080px) {
    &.sticky {
      top: 294px;
    }
    &.fixed {
      top: 294px;
    }
  }

  @media only screen and (min-width: 1080px) {
    width: calc(100vw - 494px);
    padding: 25px;
  }

  img {
    display: none;

    @media only screen and (min-width: 1080px) {
      display: block;
      width: 48px;
      height: auto;
      margin-right: 20px;
    }
  }

  p {
    font-size: 17px;
    line-height: 23px;
    font-weight: 500;
    color: #333740;
    margin: 0;

    @media only screen and (min-width: 1080px) {
      font-size: 20px;
      line-height: 27px;
      width: 727px;
    }

    a {
      color: #132787;

      &:active,
      &:hover {
        color: #009cde;
      }
    }
  }
}
