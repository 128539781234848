.breadcrumbs-navigation-outside-wrapper {
  background-color: #fff;
  z-index: 2;
}

.ab-animation-1 {
  opacity: 0;
  animation: resetAccordions1 0.5s forwards;
  position: relative;
  right: 200%;
}
.ab-animation-2 {
  opacity: 0.1;
  animation: resetAccordions2 0.5s forwards;
  position: relative;
  right: 202%;
}
@keyframes resetAccordions1 {
  from {
    opacity: 0;
    right: 200%;
  }
  to {
    opacity: 1;
    right: 0;
  }
}
@keyframes resetAccordions2 {
  from {
    opacity: 0.01;
    right: 202%;
  }
  to {
    opacity: 1;
    right: 0;
  }
}
