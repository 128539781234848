@import url("./fonts/fonts.scss");
// @import url('http://fonts.cdnfonts.com/css/avenir-next-lt-pro');
@import url("https://fast.fonts.net/cssapi/cb6b236d-f129-416d-8196-4f29ffe0c887.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Avenir Next W01", sans-serif;
  scroll-behavior: smooth;
}

body {
  overflow-y: scroll;
  position: relative;
}

.lang-switch-btn {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 42px;
  color: #00286e;
  cursor: pointer;
  text-align: end;
  padding-right: 10%;

  &:active,
  &:hover {
    text-decoration: underline;
  }

  @media only screen and (min-width: 1080px) {
    padding-right: 0;
  }
}

.abortion-checklist-main-content-container {
  background-color: #f0f0f0;
  width: 100%;

  @media only screen and (min-width: 1080px) {
    display: flex;
  }
}

#abortion-checklist-container {
  width: 100%;
  z-index: 1;
  position: relative;

  @media only screen and (min-width: 1080px) {
    max-width: 822px;
  }

  .horizontal-step-divider {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    &.padding-bottom {
      padding-bottom: 320px;
    }

    @media only screen and (min-width: 940px) {
      &.padding-bottom {
        padding-bottom: 180px;
      }
    }

    @media only screen and (min-width: 1080px) {
      &.padding-bottom {
        padding-bottom: 350px;
      }
    }

    @media only screen and (min-width: 1280px) {
      &.padding-bottom {
        padding-bottom: 300px;
      }
    }
  }
}

.pdf-download-disclaimer-wrapper-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #abe0f9;
  padding: 28px 20px;

  @media only screen and (min-width: 1080px) {
    display: none;
  }

  img {
    width: 20px;
    height: auto;
    margin-right: 16px;
  }

  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #00286e;

    &:active,
    &:hover {
      color: #009cde;
    }
  }
}
