#breadcrumbs-navigation-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  position: sticky;
  z-index: 2;
  top: 100px;
  align-items: center;

  &.its-inline {
    position: fixed;
    padding: 0;
  }

  @media only screen and (min-width: 1080px) {
    top: 294px;
    max-width: 480px;
    height: fit-content;
    border-right: 1px solid #ece8e3;
    padding: 0;
  }

  .abortion-type-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 32px 25px;
    border-bottom: 1px solid #ece8e3;

    @media only screen and (min-width: 1080px) {
      width: 100%;
      padding-left: 100px;
      padding-right: 25px;
      border: none;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      padding: 12px;
      border: 1px solid #ece8e3;
      border-radius: 8px;
      background-color: #fff;
      opacity: 0.5;
      position: relative;

      .btn-txt-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #00286e;
      }

      &:nth-child(2) {
        margin-left: 16px;
      }

      .type-checkmark {
        width: 16px;
        height: auto;
        position: absolute;
        bottom: -4px;
        right: -4px;
      }

      .type-icon {
        width: 32px;
        height: auto;
      }
    }

    .type-selected {
      opacity: 1;
      background-color: #f5f7f9;
      border: 1px solid #00286e;
    }
  }

  .its-inline {
    padding: 0;
    width: 100%;

    .hide-it-on-scroll {
      display: none;
    }

    .abortion-type-container {
      padding: 0;
    }

    .type-icon {
      width: 28px !important;
      height: auto;
    }

    button {
      border-radius: 0;
      margin-left: 0 !important;
      border-color: #f0f0f0 !important;

      .btn-txt-wrapper {
        line-height: 24px;
      }
      @media only screen and (min-width: 1080px) {
        line-height: 52px;
      }
    }

    .type-checkmark {
      width: 12px !important;
      height: auto !important;
      bottom: 20px !important;
      right: 10px !important;
    }
  }

  .breadcrumbs-container {
    display: none;
    width: 100%;
    max-width: 638px;
    height: 56px;
    list-style-type: none;

    @media only screen and (min-width: 1080px) {
      display: flex;
      flex-direction: column;
      width: 378px;
      margin-left: 100px;
      height: auto;
      margin-bottom: 47px;
    }

    &.its-inline {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .breadcrumb-step-wrapper {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .breadcrumb-step-title {
      display: none;

      @media only screen and (min-width: 1080px) {
        display: block;
        margin: 0;
        margin-left: 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #333740;
        padding-right: 20px;
      }
    }

    .breadcrumb-step-number {
      display: block;
      min-width: 24px;
      height: 24px;
      background-color: #f5f7f9;
      border-radius: 50%;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #7a8fb3;
      letter-spacing: 0;
    }

    .active .breadcrumb-step-number {
      background-color: #abe0f9;
      color: #00286e;
    }

    .breadcrumb-step-divider-line {
      display: block;
      width: 28px;
      height: 2px;
      background-color: #f5f7f9;

      @media only screen and (min-width: 1080px) {
        height: 14px;
        width: 2px;
        margin-top: 7px;
        margin-bottom: 7px;
        margin-left: 11px;
      }
    }
  }

  #spyscrollNavigation {
    display: none;
    @media only screen and (min-width: 1080px) {
      display: block;
    }
  }

  .pdf-download-disclaimer-wrapper-desktop {
    display: none;

    @media only screen and (min-width: 1080px) {
      width: 350px;
      display: flex;
      align-items: center;
      background-color: #e8e9eb;
      padding: 28px 20px;
      margin-bottom: 48px;
      margin-left: 100px;

      img {
        width: 20px;
        height: auto;
        margin-right: 16px;
      }

      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-decoration-line: underline;
        color: #00286e;

        &:active,
        &:hover {
          color: #009cde;
        }
      }
    }
  }
}
