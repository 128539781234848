#ac-header-container {
  position: relative;

  @media only screen and (min-width: 1080px) {
    &.sticky-header {
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 4;
    }
  }
  .hide-it-on-desktop {
    @media only screen and (min-width: 1080px) {
      display: none !important;
    }
  }

  .hide-it-on-mobile {
    display: none !important;

    @media only screen and (min-width: 1080px) {
      display: block !important;
    }
  }

  .ac-header-top-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #00286e;
    padding: 16px 2%;

    @media only screen and (min-width: 1080px) {
      background-color: #fff;
      padding: 0;
      padding-right: 140px;
      justify-content: flex-end;
    }

    img {
      margin-right: 20px;
      @media only screen and (max-width: 500px) {
        width: 50%;
      }
    }
  }

  .lang-switch-btn {
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    color: #fff;
    cursor: pointer;
    margin-right: 16px;
    @media only screen and (max-width: 500px) {
      margin-right: 0;
    }

    &:active,
    &:hover {
      text-decoration: underline;
    }

    @media only screen and (min-width: 1080px) {
      line-height: 40px;
      color: #00286e;
      margin: 0;
    }
  }

  .ac-header-donate-btn {
    display: inline-block;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
    background-color: #2895d5;
    width: 70px;
    font-size: 10px;
    line-height: 33px;
    text-decoration: none;
    border-radius: 15px;

    &:active,
    &:hover {
      color: #00286e;
      background-color: #fff;
    }

    @media only screen and (min-width: 1080px) {
      font-size: 17px;
      line-height: 44px;
      width: 104px;
      border-radius: 22px;
    }
  }

  .ac-header-bottom-wrapper {
    width: 100%;
    background-color: #00286e;
    padding-top: 10px;

    @media only screen and (min-width: 1080px) {
      align-items: center;
      display: flex;
      padding-left: 140px;
      justify-content: space-between;
      padding-right: 140px;
    }

    .ac-header-bottom-menu-items-container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      @media only screen and (min-width: 768px) {
        width: 70%;
        margin: 0 auto;
      }
      @media only screen and (min-width: 1080px) {
        justify-content: space-evenly;
        width: 540px;
      }
      @media only screen and (min-width: 1280px) {
        justify-content: space-between;
        width: 640px;
      }
    }

    .ac-header-main-menu-item-wrapper {
      display: flex;
      flex-direction: column;
      height: 35px;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;

      @media only screen and (min-width: 1080px) {
        height: unset;
      }

      &:active,
      &:hover {
        span {
          text-decoration: underline;
          cursor: pointer;
        }

        img {
          filter: drop-shadow(0 0 2px #fff);
        }
      }

      img {
        height: 16px;
        width: auto;
      }

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;

        @media only screen and (min-width: 1080px) {
          font-weight: 700;
          font-size: 15px;
          line-height: 24px;
          text-transform: uppercase;
        }
        @media only screen and (min-width: 1280px) {
          font-weight: 700;
          font-size: 17px;
          line-height: 24px;
          text-transform: uppercase;
        }
      }
    }

    .ac-header-search-icon-wrapper {
      @media only screen and (min-width: 1080px) {
        flex-direction: row;
      }
      img {
        @media only screen and (min-width: 1080px) {
          margin-right: 6px;
        }
      }
    }
  }

  @media only screen and (max-width: 940px) {
    &.sticky-header {
      display: none;
    }
  }
}
