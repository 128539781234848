#intro-banner-container {
  width: 100%;

  .hide-it-on-desktop {
    @media only screen and (min-width: 1080px) {
      display: none;
    }
  }

  .hide-it-on-mobile {
    display: none;

    @media only screen and (min-width: 1080px) {
      display: block;
    }
  }

  .intro-banner-inner-wrapper {
    background-color: #00286e;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;

    @media only screen and (min-width: 1080px) {
      padding: 32px 140px;
      height: auto;
    }

    .intro-banner-breadcrumbs-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      @media only screen and (min-width: 1080px) {
        margin-bottom: 47px;
      }

      a {
        font-weight: 500;
        font-size: 17px;
        line-height: 19px;
        color: #ffffff;
        text-decoration: none;

        @media only screen and (min-width: 1080px) {
          color: #2895d5;
        }

        &:last-of-type {
          color: #fff;
        }
      }

      img {
        width: 8px;
        height: auto;
        margin-left: 14px;
        margin-right: 14px;
      }

      .introbanner-breadcrumbs-dots {
        font-weight: 500;
        font-size: 17px;
        line-height: 19px;
        color: #ffffff;
        margin: 0;
      }

      .responsive-adj-show-on-550 {
        display: none;

        @media only screen and (min-width: 550px) {
          display: block;
        }
      }

      .responsive-adj-show-on-642 {
        display: none;

        @media only screen and (min-width: 642px) {
          display: block;
        }
      }

      .responsive-adj-hide-on-642 {
        display: block;

        @media only screen and (min-width: 642px) {
          display: none;
        }
      }
    }
  }

  .banner-txt-wrapper {
    width: 100%;
    max-width: 850px;
  }

  h1 {
    font-size: 36px;
    line-height: 42px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 25px;

    @media only screen and (min-width: 1080px) {
      font-size: 52px;
      line-height: 62px;
      margin: 16px 0;
    }
  }

  .banner-main-subtitle {
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    color: #fff;
    margin-bottom: 0;

    @media only screen and (min-width: 1080px) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  .banner-subtitle {
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;

    @media only screen and (min-width: 1080px) {
      font-size: 17px;
      line-height: 23px;
    }

    a {
      font-weight: 700;
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .banner-subtitle-desktop {
    color: #fff;
    margin-top: 12px;

    a {
      color: #fff;
    }
  }

  .banner-subtitle-mobile {
    color: #333740;
    margin-left: 25px;
    margin-top: 20px;
    margin-right: 25px;

    a {
      color: #00286e;
      font-weight: 500;
    }
  }

  &.sticky-banner {
    position: fixed;
    top: 0;
    z-index: 3;

    @media only screen and (max-width: 1079px) {
      .intro-banner-breadcrumbs-wrapper {
        display: none;
      }

      .banner-main-subtitle {
        display: none;
      }

      .banner-subtitle-mobile {
        display: none;
      }

      .intro-banner-inner-wrapper {
        padding: 8px;
        height: 100px;
        .banner-txt-wrapper {
          display: flex;
          align-items: center;
        }
        h1 {
          font-size: 17px;
        }
      }

      h1 {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (min-width: 1080px) {
    &.sticky-banner {
      position: fixed;
      top: 94px;
      z-index: 3;

      .banner-main-subtitle,
      .banner-subtitle-desktop {
        display: none;
      }
      .intro-banner-inner-wrapper {
        padding-bottom: 0;
        height: 200px;
        .intro-banner-breadcrumbs-wrapper {
          margin-bottom: 30px;
        }
        .banner-txt-wrapper {
          h1 {
            font-size: 30px;
          }
        }
      }
    }
  }
}
