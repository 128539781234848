#ac-header-search-box-container {
  width: 100%;
  position: absolute;
  top: 120%;
  background-color: #fff;

  .ac-header-search-box-inner-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 25px;
    padding-bottom: 50px;

    img {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      transition: transform 0.5s;

      @media only screen and (min-width: 1080px) {
        top: 20px;
        right: 20px;
      }

      &:active,
      &:hover {
        transform: scale(1.2);
      }
    }

    p {
      color: #00286e;
      font-size: 14px;
      margin-bottom: 5px;

      @media only screen and (min-width: 1080px) {
        margin-left: 140px;
      }
    }

    .ac-header-search-input-wrapper {
      width: 100%;
      border: 2px solid #2895d5;
      border-radius: 22px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 2px;
      padding-top: 2px;
      padding-bottom: 2px;

      @media only screen and (min-width: 1080px) {
        margin-left: 140px;
        width: 100%;
        max-width: 1136px;
      }

      input {
        width: 100%;
        outline: 0;
        border: 0;
        line-height: 40px;
        z-index: 1;
        color: #00286e;
        &:focus .ac-header-search-input-wrapper {
          border: 2px solid #00286e;
        }
      }

      span {
        z-index: 2;
        display: inline-block;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
        background-color: #2895d5;
        width: 104px;
        font-size: 10px;
        line-height: 44px;
        text-decoration: none;
        border-radius: 22px;

        &:active,
        &:hover {
          background-color: #00286e;
          cursor: pointer;
        }

        @media only screen and (min-width: 1080px) {
          font-size: 17px;
        }
      }
    }
  }
}
